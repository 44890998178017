import React, { useState } from 'react'
import CardArticle from '../../../shared/components/molecules/event-card-article/event-card-article'
import CardEvent from '../../../shared/components/molecules/event-card/event-card'
import Spacer from '../../../shared/components/atoms/spacer/spacer'
import './incontinenciaContent.scss'
import useLocaleMode from '../../../shared/hooks/useLocaleMode'
import LeafletCard from '../../../shared/components/molecules/leaflet-card/leaflet-card'
import SeoProductInfo from '../../../shared/components/molecules/seo-product-info/seo-product-info'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const IncontinenciaContent = props => {
	const articleBase = 3
	const [numArticles, setnumArticles] = useState(articleBase)
	const { content } = props
	const { locale } = useLocaleMode()
	const userInfo=dataLayerUserInfo()
	const dataLayerIncontinencia = (titulo) => {
		let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Incontinencia',
		category: 'incontinencia',
		action: 'click',
		label: titulo,
		location: loc,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}
	return (
		<section className='f-incontinencia-container'>
			<div className='f-incontinencia-content'>
				<Spacer px={10} />
				<Spacer px={25} hideon={'small'} />

				<h2 className='f-h2'>{content.titulo} </h2>
				<p className='f-p'> {content.descripcion} </p>

				<Spacer px={5} />

				<div className='f-articles-container'>
					{content.articulos.map((evento, index) => {
						if (index < numArticles) {
							if (evento.id) {
								return <CardArticle article={evento} key={index} />
							} else {
								return <CardEvent event={evento} key={index} />
							}
						}
						return null
					})}
				</div>

				<Spacer px={30} hideon='x-large' />

				<div
					className={`f-articles-section-cargar f-btn-articles-${content.slug}`}
				>
					{content.articulos.length > numArticles && (
						<button
							className='f-button f-button--primary'
							onClick={() => {
								dataLayerIncontinencia('Conoce más sobre incontinencia')
								setnumArticles(numArticles + articleBase)
							}}
						>
							{convertText(content.ctaIncontinencia)}
						</button>
					)}
				</div>
				{content.informacionAdicional && (
					<SeoProductInfo seoInfo={content.informacionAdicional} />
				)}
				{props.isLoggedUser ? null : (
					<LeafletCard
						isMobile={props.isMobile}
						leafletContent={props.leafletContent}
					/>
				)}
				{locale === 'es-CO' ? (
					<>
						<Spacer px={40} />

						{/* <DestacadoMuestra content={content.elementoDestacado} /> */}
					</>
				) : (
					<></>
				)}
			</div>
		</section>
	)
}

export default IncontinenciaContent
