import React from 'react'
import Img from 'gatsby-image'
import { DateTime } from 'luxon'
import SessionService from '../../../services/session'
import ModalAuth from '../../molecules/modalAuth/modal-auth'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import useMobileMode from '../../../hooks/useMobileMode'
import exclusiveIcon from '../../../assets/images/exclusive-icon.svg'
import Icon from '../../../components/atoms/Icon/Icon'
import { sessionStorage } from '../../../state/utils/storage'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'
import { convertText } from '../../../utils/convertFirstCharacterToUppercase'
import './event-card.scss'

const webinarTypes = {
	'cuidador-profesional-conferencistas': 'f-tag-color--green',
	mujeres: 'f-tag-color--purple',
}

const EventCard = props => {
	const { event } = props
	const [isModalAuth, setIsModalAuth] = React.useState(false)
	const { isMobile } = useMobileMode()
	const { homeLink } = useHomeLinkMode()
	const isActiveEvent = props.isActiveEvent
		? props.isActiveEvent
		: event.estadoWebinar?.toLowerCase() !== 'finalizado el evento'
	const dateEvent = DateTime.fromISO(event.fechaDelEvento)
		.setLocale('es-CO')
		.setZone('America/Bogota')

	const getFinalSlug = () => {
		const baseSlug = homeLink + 'academia-tena/'
		const hasOneConference =
			event.referenciasDeConferencistasLecciones?.length === 1

		return `${baseSlug}${event.slug} ${
			hasOneConference ? event.referenciasDeConferencistasLecciones[0].slug : ''
		}`
	}

	const dataLayer = () => {
		window.dataLayer = window.dataLayer || []
		if (props.dataLayer) {
			props.dataLayer(event)
		}
	}

	const dataLayerContenidoAcademia = (articleName, subCat) => {      
		const title = event.estadoWebinar === 'Finalizado el Evento' ? 'VER ' +
			(event.tipoDeEvento.toLowerCase() === 'conferencia virtual'
				? 'CONFERENCIA'
				: event.tipoDeEvento?.toUpperCase())
			: titleStatus()
		let location=''
		let host = ''
		if (typeof window !== 'undefined') {
				location= window.location.href
				host= window.location.hostname.split('www.')[0]
		}
		pushDataLayerEvent({event: 'Interacciones - Academia',
		category: 'academia tena',
		action: 'click',
		label: articleName,
		sub_category: subCat,
		location,
		video_name: articleName,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})

		if(title=== 'VER CONFERENCIA'){
			pushDataLayerEvent({
				event: "cta_click",
				link_text: "VER CONFERENCIA",
				link_url: homeLink + 'academia-tena/'+event.slug,
				link_domain: host,
				link_id: "",
				link_classes: ""
			})
		}else{
			pushDataLayerEvent({
				event: "article_click",
				link_text: articleName,
				link_url: homeLink + 'academia-tena/'+event.slug,
				article_name: articleName
			})
		}
	}

	const handleRedirection = () => {
		dataLayer()
		const prospectUser = sessionStorage.getItem('PROSPECT_TEMP_USER')
		const tokenId = SessionService.getIdTokenData()
		if (event.esUnEventoExclusivo) {
			if ((prospectUser && prospectUser !== null) || tokenId) {
				window.location.href = getFinalSlug()
			} else {
				if (props.setIsModalAuth) {
					props.setIsModalAuth(event)
				} else {
					setIsModalAuth(true)
				}
			}
		} else {
			window.location.href = getFinalSlug()
		}
	}

	const titleStatus = () => {
		let title
		if (event.estadoWebinar.toLowerCase() === 'después del evento') {
			title = 'Descargar certificado'
		} else if (event.estadoWebinar.toLowerCase() === 'en el evento') {
			title = 'Ingresa a la conferencia'
		} else {
			title = 'Inscríbete aquí'
		}
		return title
	}

	const buttonAction = (
		<button className='f-card-event__cta' onClick={handleRedirection}>
			{convertText(event.estadoWebinar === 'Finalizado el Evento'
				? 'VER ' +
				  (event.tipoDeEvento.toLowerCase() === 'conferencia virtual'
						? 'CONFERENCIA'
						: event.tipoDeEvento?.toUpperCase())
				: titleStatus())}
		</button>
	)

	const userInfo=dataLayerUserInfo()
	const dataLaterwebinar = (titulo,categoria,tipoArt) => {
		let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Articulos',
		category: 'academia tena',
		action: 'click',
		label: titulo,
		article_name: titulo,
		sub_category:categoria,
		location: loc, 
		article_type:tipoArt,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}
	return (
		<React.Fragment>
			<article
				className={`f-card-event ${webinarTypes[event.tipoDeWebinar] ||
					'f-tag-color--green'} ${isActiveEvent ? 'f-card-event--curve' : ''}`}
				onClick={()=>{
					dataLaterwebinar(event.nombreDelEvento,event.temasDelEvento[0]?.nombreDeEtiqueta,event.tipoDeEvento)
					dataLayerContenidoAcademia(event.nombreDelEvento, event.temasDelEvento[0]?.nombreDeEtiqueta||undefined)
					handleRedirection()}}
			>
				<div className='f-card-event__img-container'>
					<Img
						className='f-card-event__img-container__img'
						fluid={event.imagenPrevisualizacion[0]?.fluid}
						objectFit='contain'
					/>
					{event.estadoWebinar === 'Finalizado el Evento' &&
						(event.categoriaTipoDeEvento &&
							event.categoriaTipoDeEvento?.imagen) && (
							<img
								className='f-card-event__img-container__type-img'
								src={event.categoriaTipoDeEvento?.imagen?.file.url}
							/>
						)}
					{event.esUnEventoExclusivo && (
						<img
							className='f-card-event__img-container__icon-exclusive'
							src={exclusiveIcon}
							alt='exclusive icon'
						/>
					)}
				</div>

				<div className='f-card-header-wrapper'>
					<p
						className={`f-card-event__category ${webinarTypes[
							event.tipoDeWebinar
						] || 'f-tag-color--green'}`}
					>
						{event.temasDelEvento && event.temasDelEvento[0].nombreDeEtiqueta}
					</p>
					<p className='f-card-event__type'>
						{event.tipoDeEvento === 'Webinar'
							? 'CONFERENCIA VIRTUAL'
							: event.tipoDeEvento?.toUpperCase()}{' '}
						{event.esUnEventoExclusivo ? <b>EXCLUSIVO</b> : ''}
					</p>
				</div>

				<div className='f-card-content'>
					<h2 className='f-card-event__title'> {event.nombreDelEvento} </h2>
					<h3 className='f-card-event__subtitle'>
						{' '}
						{event.encargadosDelWebinar}{' '}
					</h3>

					<div className='f-card-event-mobile-wrapper'>
						<p className='f-card-event__description'>
							{dateEvent && (
								<>
									<Icon icon='fal fa-calendar' size='0-7' tagtype='i' />
									{isMobile
										? dateEvent.toFormat('dd/MMM/yyyy')?.replace('.', '')
										: dateEvent.toFormat('dd-MMMM-yyyy')?.replace(/-/g, ' de ')}
								</>
							)}
						</p>
						{buttonAction}
					</div>
				</div>

				{isActiveEvent && buttonAction}
			</article>

			{isModalAuth && (
				<ModalAuth
					isFromEvent={true}
					hideAction={() => {
						if (props.setIsModalAuth) {
							props.setIsModalAuth(false)
						} else {
							setIsModalAuth(false)
						}
					}}
					onSuccessProspectRegister={data => {
						sessionStorage.setItem('PROSPECT_TEMP_USER', data)
						handleRedirection()
					}}
					validateCoverage={() => false}
					showModalCoverage={() => {
						handleRedirection()
					}}
					isWebinar={true}
					title={
						<>
							Para acceder al contenido de <span>TENA,</span> debes ingresar a
							tu cuenta o registrarte.
						</>
					}
				/>
			)}
		</React.Fragment>
	)
}

export default EventCard
