import React from 'react'
// import { Link } from "gatsby"
import Img from 'gatsby-image'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'
import './bannerCTA.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const BannerCTA = props => {
	const imgDesktop = props.bannerInfo.bannerDesktop
	const imgMobile = props.bannerInfo.bannerMobile
	const isMobile = props.isMobile
	const { bannerInfo, slug } = props

	const dataLayerComunidad = () =>{
		if(slug==='incontinencia'){
			let location = ''
			let host = ''
			if (typeof window !== 'undefined') {
				location = window.location.href
				host = window.location.hostname
			}
			pushDataLayerEvent({
				event: "cta_click",
				link_text: props.bannerInfo.cta,
				link_url: location,
				link_domain: host.split('www.')[0],
				link_id: "",
				link_classes: `f-btn-promo f-btn-promo-${slug}`
			})
		}
	}

	const homeLink =
		typeof window !== 'undefined' ? '//' + window.location.host + '/' : '/'

	return (
		<div className='f-banner-cta'>
			<div
				className={`f-description-wrapper f-description-wrapper-${bannerInfo.localizacionTexto}`}
			>
				<h2 className={`f-banner-title f-text-color-${bannerInfo.colorTexto}`}>
					{bannerInfo.nombre}
				</h2>
				<p
					className={`f-banner-description f-text-color-${bannerInfo.colorTexto}`}
				>
					{bannerInfo.descripcionImagen}
				</p>
				<a href={homeLink + bannerInfo.enlace}>
					<button className={`f-btn-promo f-btn-promo-${slug}`} onClick={()=>dataLayerComunidad()}>
						{convertText(props.bannerInfo.cta)}
					</button>
				</a>
			</div>
			<Img
				className='f-img-banner'
				fluid={isMobile ? imgMobile.fluid : imgDesktop.fluid}
				alt={imgDesktop.description}
			/>
		</div>
	)
}

export default BannerCTA
