import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import './rich-text.scss'

const RichText = props => {
	const { jsonInfo, className, ...params } = props
	const optionDescription = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, next) => {
				if (next !== '' && next !== null) {
					return <p>{next}</p>
				} else {
					return null
				}
			},
			[BLOCKS.EMBEDDED_ASSET]: (node, next) => {
				if (node.data.target.fields) {
					return (
						<img
							className='f-rich-img'
							src={node.data.target.fields.file['es-CO'].url}
							alt={node.data.target.fields.description['es-CO']}
						/>
					)
				} else {
					return null
				}
			},
			[BLOCKS.PARAGRAPH]: (node, next) => {
				if (next && next[0] !== '' && next[0] !== ' ') {
					return <p>{next}</p>
				}
			},
			[INLINES.HYPERLINK]: (node, next) => (
				<a className='f-link' rel='noopener noreferrer' href={node.data.uri}>
					{next}
				</a>
			),
		},
	}

	return (
		<div className={'rich-text-container ' + (className || '')} {...params}>
			{documentToReactComponents(jsonInfo && jsonInfo, optionDescription)}
		</div>
	)
}

export default RichText
