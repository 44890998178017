import { graphql } from 'gatsby'
import React from 'react'
import BannerCTA from '../components/molecules/bannerCTA/bannerCTA'
import IncontinenciaContent from '../components/organisms/incontinenciaContent/incontinenciaContent'
import Layout from '../shared/components/organisms/layout/layout'
import SEO from '../shared/components/seo'
import useMobileMode from '../shared/hooks/useMobileMode'
import useSessionState from '../shared/hooks/useSessionState'

const Incontinencia = props => {
	const { isMobile, currentPage } = useMobileMode()
	const {
		allContentfulTenaSeccionIncontinencia,
		allContentfulTenaRegistroV2,
	} = props.data
	const { isLoggedUser } = useSessionState()

	return (
		<Layout title={allContentfulTenaSeccionIncontinencia.nodes[0].metaTitulo} isMobile={isMobile} currentPage={currentPage}>
			<SEO
				title={allContentfulTenaSeccionIncontinencia.nodes[0].metaTitulo}
				description={
					allContentfulTenaSeccionIncontinencia.nodes[0].metaDescripcion
						.metaDescripcion
				}
				image={
					allContentfulTenaSeccionIncontinencia.nodes[0].banner?.bannerDesktop
						?.fluid?.src
				}
				slug={'/incontinencia/'}
				lang={props.pageContext.language}
			/>
			<BannerCTA
				isMobile={isMobile}
				slug={allContentfulTenaSeccionIncontinencia.nodes[0].slug}
				bannerInfo={allContentfulTenaSeccionIncontinencia.nodes[0].banner}
			/>
			<IncontinenciaContent
				leafletContent={allContentfulTenaRegistroV2.nodes[0]}
				isMobile={isMobile}
				isLoggedUser={isLoggedUser}
				content={allContentfulTenaSeccionIncontinencia.nodes[0]}
			/>
		</Layout>
	)
}

export const query = graphql`
	query GET_INCONTINENCIA($language: String!) {
		allContentfulTenaSeccionIncontinencia(
			filter: { node_locale: { eq: $language } }
		) {
			nodes {
				slug
				titulo
				descripcion
				ctaIncontinencia
				metaDescripcion {
					metaDescripcion
				}
				metaTitulo
				elementoDestacado {
					elementosHtml {
						json
					}
				}
				banner {
					nombre
					descripcionImagen
					cta
					enlace
					colorTexto
					localizacionTexto
					bannerDesktop {
						description
						fluid(maxWidth: 1800, quality: 90) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
					bannerMobile {
						description
						fluid(maxWidth: 600, quality: 90) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
				}
				informacionAdicional {
					tipoDeInformacionAdicional
					informacionAdicional1 {
						json
					}
					informacionAdicional2 {
						json
					}
					informacionAdicional3 {
						json
					}
					imagenInformacionAdicional1 {
						file {
							url
						}
					}
					imagenInformacionAdicional2 {
						file {
							url
						}
					}
					imagenInformacionAdicional3 {
						file {
							url
						}
					}
				}
				articulos {
					... on ContentfulTenaEventos {
						slug
						identificadorEvento
						imagenPrevisualizacion {
							fluid(maxWidth: 530) {
								aspectRatio
								base64
								sizes
								src
								srcSet
							}
						}
						tipoDeWebinar
						tipoDeEvento
						temasDelEvento {
							nombreDeEtiqueta
						}
						enlaceDelEvento
						esUnEventoExclusivo
						eventoActivo
						nombreDelEvento
						encargadosDelWebinar
						estadoWebinar
						fechaDelEvento
						categoriaTipoDeEvento {
							nombreDeEtiqueta
							idEtiqueta
							imagen {
								file {
									url
								}
							}
							imagenHtmlSvg {
								imagenHtmlSvg
							}
						}
						dirigidoA {
							nombreDeEtiqueta
							imagen {
								title
								description
								fluid(maxWidth: 500, quality: 80) {
									aspectRatio
									base64
									sizes
									src
									srcSet
								}
							}
						}
						referenciasDeConferencistasLecciones {
							metaTitulo
							metaDescripcion {
								metaDescripcion
							}
							slug
							imagenConferencistaLeccion {
								title
								description
								file {
									url
								}
								fluid {
									aspectRatio
									base64
									sizes
									srcSet
									src
								}
							}
							banderaConferencista {
								title
								description
								file {
									url
								}
							}
							nombreConferencistaLeccionista
							tituloDelConferencistaLeccion
							tipoDeLeccion {
								nombreDeEtiqueta
								imagen {
									file {
										url
									}
								}
							}
							nombreConferenciaLeccion {
								json
							}
							informacionCharlaConferencista {
								json
							}
							horasConferencia {
								json
							}
							fechaConferenciaLeccion
							memoriasConferenciaPdfLeccion {
								file {
									url
								}
							}
							videoDeLaConferenciaLeccion
							sobreLaCharlaLeccion {
								json
							}
						}
					}
					... on ContentfulTenaArticulos {
						id
						slug
						titulo
						autorDelArticulo
						descripcionArticulo
						fechaDePublicacion
						fechaDePublicacionFormated: fechaDePublicacion(
							formatString: "DD [de] MMMM [de] YYYY"
							locale: "es-CO"
						)
						imagenArticuloPrevisualizacion {
							fluid(maxWidth: 700, quality: 90) {
								src
								srcSet
								base64
								aspectRatio
								sizes
							}
							description
						}
						categoria {
							titulo
							nombreDeEtiqueta
							slug
						}
						tipoDelArticulo {
							nombreDeEtiqueta
							idEtiqueta
							imagen {
								file {
									url
								}
							}
							imagenHtmlSvg {
								imagenHtmlSvg
							}
						}
					}
				}
			}
		}
		allContentfulTenaRegistroV2(
			filter: {
				titulo: { eq: "Registro > Pauta" }
				node_locale: { eq: $language }
			}
		) {
			nodes {
				titulo
				banner {
					description
					fluid(maxWidth: 1200, quality: 100) {
						src
						srcSet
						base64
						aspectRatio
						sizes
					}
				}
				informacionPrincipal {
					json
				}
				informacionPrincipalTitulo {
					json
				}
				botonSiguiente
				botonFinalizar
				inputCorreoElectronico
				inputNombres
				inputCelular
				InputApellidos
				registroProspectoInscripcionExitosa {
					json
				}
				registroProspectoYaInscrito {
					json
				}

				registroProspectoRecomendados {
					nombreDeEtiqueta
					redireccion
					imagen {
						description
						file {
							url
						}
					}
				}
			}
		}
	}
`

export default Incontinencia
