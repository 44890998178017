import React from 'react'
import Img from 'gatsby-image'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import useMobileMode from '../../../hooks/useMobileMode'
import Icon from '../../../components/atoms/Icon/Icon'
import { DateTime } from 'luxon'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'
import './event-card-article.scss'
import { convertText } from '../../../utils/convertFirstCharacterToUppercase';

const EventCardArticle = props => {
	const { isMobile } = useMobileMode()
	const { homeLink } = useHomeLinkMode()
	const { article } = props
	const articleCategory = (article.categoria && article.categoria[0]) || null
	const dateArticle = DateTime.fromISO(article.fechaDePublicacion || '')
		.setLocale('es-CO')
		.setZone('America/Bogota')

	const dataLayer = () => {
		window.dataLayer = window.dataLayer || []
		if (props.dataLayer) {
			props.dataLayer(article)
		}
	}

	const userInfo=dataLayerUserInfo()
	const dataLaterwebinar = (titulo,categoria,tipoArt, link) => {
		let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Articulos',
		category: 'academia tena',
		action: 'click',
		label: titulo,
		article_name: titulo,
		sub_category:categoria,
		location: loc, 
		article_type:tipoArt,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})

		pushDataLayerEvent({
			event: "article_click",
			link_text: titulo,
			link_url: link,
			article_name: titulo
		})
	}
	return (
		<a
			href={homeLink + 'academia-tena/' + article.slug}
			onClick={()=>{
				dataLaterwebinar(article.titulo,article.categoria[0]?.nombreDeEtiqueta,article.tipoDelArticulo?.nombreDeEtiqueta, homeLink + 'academia-tena/' + article.slug)
				dataLayer()
			}}
			className='f-card-article'
		>
			<div className='f-card-article__img-container'>
				<Img
					className='f-card-article__img-container__img'
					fluid={article.imagenArticuloPrevisualizacion[0]?.fluid}
					objectFit='contain'
				/>
				{article.tipoDelArticulo && article.tipoDelArticulo?.imagen && (
					<img
						className='f-card-article__img-container__type-img'
						src={article.tipoDelArticulo?.imagen?.file.url}
					/>
				)}
			</div>

			<div className='f-card-header-wrapper'>
				{articleCategory && (
					<p className='f-card-article__category'>
						{articleCategory?.nombreDeEtiqueta}
					</p>
				)}
				<p className='f-card-article__type'>ARTÍCULO</p>
			</div>

			<div className='f-card-content'>
				<h2 className='f-card-article__title'> {article.titulo} </h2>
				<h3 className='f-card-article__subtitle'>
					{' '}
					{article.autorDelArticulo}{' '}
				</h3>

				<div className='f-card-article-mobile-wrapper'>
					<p className='f-card-article__description'>
						{dateArticle && dateArticle.isValid && (
							<>
								<Icon icon='fal fa-calendar' size='0-7' tagtype='i' />
								{isMobile
									? dateArticle.toFormat('dd/MMM/yyyy')?.replace('.', '')
									: dateArticle.toFormat('dd-MMMM-yyyy')?.replace(/-/g, ' de ')}
							</>
						)}
					</p>
					<a
						href={homeLink + 'academia-tena/' + article.slug}
						className='f-card-article__cta'
						onClick={(dataLayer)}
					>
						{convertText('LEER ARTÍCULO')}
					</a>
				</div>
			</div>
		</a>
	)
}

export default EventCardArticle
